<template>
	<div class="container">
		<div class="header">个人中心</div>
		<div class="blian22"></div>
		<div class="user_head_infor" v-if="info.username">
			<div class="head_img"> <img :src="info.headPhoto" /></div>
			<div class="user_infor">
				<div class="name">{{ info.username }}</div>
				<p>{{ info.gender==1?'男':'女' }}</p>
			</div>
			<!--<div class="user_right">
				<img src="../assets/images/right.png" />
			</div>-->
		</div>
		<div class="user_head_infor" v-else  @click="wxLogin">
			<div class="head_img"> <img :src="info.headPhoto" /></div>
			<div class="user_infor">
				<div class="name" style="margin-top: 1rem;" >登录体验更多</div>
			</div>
			<div class="user_right">
				<img src="../assets/images/right.png" />
			</div>
		</div>
		<div class="user-list">
			<ul v-if="info.username">
				<li>
					<router-link to="/myactivity">
						<img src="../assets/images/icon2.png" alt="" class="icon">
						<span>购书满赠活动</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
				<li>
					<router-link to="/myaccount">
						<img src="../assets/images/icon3.png" alt="" class="icon">
						<span>账号设置</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
				<li>
					<router-link to="/userAgreement">
						<img src="../assets/images/icon1.png" alt="" class="icon">
						<span>用户协议</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
				<li>
					<router-link to="/privacy">
						<img src="../assets/images/icon2.png" alt="" class="icon">
						<span>隐私政策</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
			</ul>
			<ul v-else>
				<!--未登录-->
				<li @click="goList">
					<img src="../assets/images/icon2.png" alt="" class="icon">
					<span>购书满赠活动</span>
					<img src="../assets/images/right.png" alt="" class="right">
				</li>
				<li @click="goList">
					<img src="../assets/images/icon3.png" alt="" class="icon">
					<span>账号设置</span>
					<img src="../assets/images/right.png" alt="" class="right">
				</li>
				<li>
					<router-link to="/userAgreement">
						<img src="../assets/images/icon1.png" alt="" class="icon">
						<span>用户协议</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
				<li>
					<router-link to="/privacy">
						<img src="../assets/images/icon2.png" alt="" class="icon">
						<span>隐私政策</span>
						<img src="../assets/images/right.png" alt="" class="right">
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import { userInfo, login, preLoginUrl } from "@/assets/js/api";
	import { Toast } from 'mint-ui';
	export default {
		name: "user",
		data() {
			return {
				code: '',
				appid: '',
				redirectUrl: '',
				info: {},
			};
		},
		methods: {
			//获取用户信息
			getuserInfo() {
				userInfo({}).then(res => {
					if(res.code == 0) {
						this.info = res.userInfo;
					}
				});
			},
			//未登录提示
			goList() {
				Toast({
					message: '授权登陆获取更多体验权限',
					position: 'middle',
					duration: 1000
				});
			},
			//请求微信授权
			preLoginUrl() {
				let learnSection = ''
				this.$dialog.confirm({
					message: "请选择您所在学段",
					confirmButtonText: "初中", //改变确认按钮上显示的文字
					cancelButtonText: "小学" //改变取消按钮上显示的文字
				}).then(() => {
					learnSection = 2
					this.preLoginUrlNew(learnSection)
				}).catch(() => {
					learnSection = 1
					this.preLoginUrlNew(learnSection)
				})
			},
			preLoginUrlNew(learnSection){
				preLoginUrl({
					redirectUrl: this.redirectUrl,
					learnSection: learnSection
				}).then(res => {
					if(res.code == 0) {
						window.location.href = res.url;
					}
				});
			},
			login() {
				login({
					appid: this.appid,
					code: this.code,
				}).then(res => {
					if(res.code == 0) {
						localStorage.setItem('token', res.token);
						localStorage.setItem('userId', res.userId);
						this.getuserInfo();
						
					}
				});
			},
			//微信登陆
			wxLogin() {
				this.redirectUrl = window.location.href; //获取当前地址
				this.preLoginUrl(); //请求微信登录授权
			}
		},
		mounted() {
			if(this.$route.query.code && !localStorage.getItem('token')) {
				//有code说明是微信回调
				this.code = this.$route.query.code;
				this.appid = this.$route.query.appid;
				this.login()
			}
			if(localStorage.getItem('token')) {
				this.getuserInfo();
			}
			
		}
	};
</script>
<style>
	.van-dialog__confirm, .van-dialog__confirm:active{
		color: #333 !important;
	}
</style>